import { Component, Vue } from "vue-property-decorator";

import { Header ,Footer} from "@/layout";
import { QuizModel } from "@/models";
import { TeCommon } from "../Common";

type response = { [key: string]: unknown };

@Component<ApTeacherStudentLearningController>({
    components: {
        Header,Footer
    }
})
export default class ApTeacherStudentLearningController extends Vue {
    private resultList: object = {};
    private num = 0;
    private page = 1;
    private perpage = 50;
    private maxPage = 0;
    private stid = this.$route.query.stid as string;
    private token = window.localStorage.getItem('teacherToken') as string;

    public created() {
        this.getResultList();
    }

    public async getResultList() {
        const item = {
            page: this.page,
            perpage: this.perpage,
            stid: this.stid,
            token: this.token,
        }
        const data = await QuizModel.teGetLearningList(item) as response;
        TeCommon.checkToken(data.ERR_CODE as number);
        this.num = data.num as number;
        this.resultList = data.list as object;
        this.maxPage = Math.ceil(this.num/this.perpage);
    }

    private getPageList(page: number) {
        this.page = (page < 1) ? 1 : (page > this.maxPage) ?  this.maxPage : page;
        this.getResultList();
    }
}